<template>
  <div class="store-info">
      <img src="https://axhub.im/ax9/63624dca376fb47f/images/%E7%BB%8F%E8%90%A5%E8%AF%81%E6%98%8E/u0.png" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.store-info {
    width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>